<template>
    <div class="row">
        <div class="col-12 pl-0 pr-0">
            <div class="row titleRow">
                <div class="col-4">
                <div class="row">
                    <div class="col-12 text-left">
                        <strong>Component</strong>
                    </div>
                </div>
                </div>
                <div class="col-2">
                <div class="row">
                    <div class="col-12">
                        <strong>Score</strong>
                    </div>
                </div>
                </div> 
                <div class="col-6">
                    <div class="row">
                        <div class="col-12  text-left">
                            <strong>Suggestions</strong>
                            </div>
                    </div>
                </div>
            </div>
            <div class="row fluencyRow text-center">
                <div class="col-4 text-left">Content</div>
                <div class="col-2">{{apiResults.content}}/{{apiResults.contentMax}}</div>
                <div class="col-6 text-left">{{suggestionWords(apiResults.content,apiResults.contentMax,"Content")}}</div>
            </div>
            <div class="row fluencyRow text-center">
                <div class="col-4 text-left">Form</div>
                <div class="col-2">{{apiResults.form}}/{{formMax}}</div>
                <div class="col-6 text-left">{{suggestionWords(apiResults.form,formMax,"Form")}}</div>
            </div>
            <div class="row fluencyRow text-center">
                <div class="col-4 text-left">Grammar</div>
                <div class="col-2">{{apiResults.grammer}}/2</div>
                <div class="col-6 text-left">{{suggestionWords(apiResults.grammer,2,"Grammar")}}</div>
            </div>
            <div class="row fluencyRow text-center" v-if="showSpelling">
                <div class="col-4 text-left">Spelling</div>
                <div class="col-2">{{apiResults.spelling}}/2</div>
                <div class="col-6 text-left">{{suggestionWords(apiResults.spelling,2,"Spelling")}}</div>
            </div>
            <div class="row fluencyRow text-center" v-if="showStructure">
                <div class="col-4 text-left">Structure</div>
                <div class="col-2">{{apiResults.structure}}/2</div>
                <div class="col-6 text-left">{{suggestionWords(apiResults.structure,2,"Structure")}}</div>
            </div>
            <div class="row fluencyRow text-center">
                <div class="col-4 text-left">Vocabulary</div>
                <div class="col-2">{{apiResults.vocab}}/2</div>
                <div class="col-6 text-left">{{suggestionWords(apiResults.vocab,2,"Vocabulary")}}</div>
            </div>
            <div class="row fluencyRow text-center" v-if="showWrittenDiscourse">
                <div class="col-4 text-left">Written Discourse</div>
                <div class="col-2">{{writtenDiscourse}}/2</div>
                <div class="col-6 text-left">{{suggestionWords(writtenDiscourse,2,"Written")}}</div>
            </div>
            <div class="row">
                <div class="col-12 pt-1 pb-1" style="background: #b2b8c9;">
                    <p>Max Score: {{apiResults.maxMarks}},   Your Score: <strong :class="currentClass">{{overAllScore}}</strong></p>
                </div>
            </div>
            <div class="row textRow m-3" style="position: relative;">
                <div class="noteDiv"><span style="padding-right: 10px;"><i class="fas fa-square" style="color:#f33176;padding-right: 5px;"></i>Possible Mistake</span><span><i class="fas fa-square" style="color:yellow;padding-right: 5px;"></i>Deduction</span></div>
                <p id="answerTrans" style="white-space: pre-wrap;width: 100%;
    padding-top: 10px;" v-html="apiResults.transcript"></p>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
export default {
    props:['apiResults'],
    data(){
        return {
            apiOutPut: this.apiResults,
            overAllScore:0,
            currentClass:'text-success',
            content:'Great!',
            form:'Great!',
            spelling:'Great!',
            grammer:'Great!',
            structure:'Great!',
            vocab:'Great!',
            showStructure: false,
            showSpelling: false,
            showWrittenDiscourse:false,
            writtenDiscourse:2,
            formMax:2

        }
    },
    methods:{
         suggestionWords(marks,totalMarks,type){
            if(type=="Content"){
                if(marks==totalMarks) return 'Great!'
                else if(marks >= totalMarks*.75) return 'Good'
                else if(marks >= totalMarks*.65) return 'Average'
                else return 'Poor' 
            }
            else{
                if(this.apiOutPut.formMsg==""){
                    if(marks==totalMarks) return 'Great!'
                    else if(marks >= totalMarks*.75) return 'Good'
                    else if(marks >= totalMarks*.65) return 'Average'
                    else return 'Poor' 
                }
                else{
                    if(type=="Form"){
                        return this.apiOutPut.formMsg;//"SWT answer can only have 1 sentence. You have 2 sentences in your answer. 0 for form and no further scoring."
                    }
                    else{
                        return "No further scoring"
                    }
                }
            }
            
             

             
        }
    },
    created(){
        // this.overAllScore =(this.apiResults.content+this.apiResults.form+this.apiResults.spelling
        //         +this.apiResults.grammer+this.apiResults.structure+this.apiResults.vocab)
        if(this.apiResults.quest=='swt'){
            //this.overAllScore=this.overAllScore-(this.apiResults.structure+this.apiResults.spelling)
            this.overAllScore =(this.apiResults.content+this.apiResults.form
                +this.apiResults.grammer+this.apiResults.vocab)
            this.formMax=1
        }
        else if(this.apiResults.quest=='sst')
        {
            ////debugger
            this.showSpelling=true
            //this.overAllScore=this.overAllScore-this.apiResults.spelling
            this.overAllScore =(this.apiResults.content+this.apiResults.form
                +this.apiResults.grammer+this.apiResults.spelling+this.apiResults.vocab)
        }
        else {
            this.showSpelling=true
            this.showStructure=true
            this.showWrittenDiscourse=true
            if(this.apiResults.content>=2)
            {
                this.writtenDiscourse=2
            }
            else if(this.apiResults.content>=1){
                this.writtenDiscourse=this.apiResults.content-0.15
            }
            else{
                this.writtenDiscourse=this.apiResults.content
            }
             this.overAllScore =(this.apiResults.content+this.apiResults.form+this.apiResults.spelling
             +this.apiResults.grammer+this.apiResults.structure+this.apiResults.vocab+this.writtenDiscourse)
            //this.overAllScore=this.overAllScore+this.writtenDiscourse
        }
        
        if(this.overAllScore < this.apiResults.maxMarks*.75) this.currentClass ='text-warning'
        else if(this.overAllScore < this.apiResults.maxMarks*.5) this.currentClass ='text-danger'
    }
}
</script>
<style scoped>


.titleRow
{
background: #b2b8c9;
min-height: 30px;
text-align: center;
line-height: 30px;
font-size: 15px;
}
.pronunciationRow, .contentRow{
    padding: 1rem;
border-bottom: 1px solid #ccc;
}
.fluencyRow div{
padding: 1rem;
border-bottom: 1px solid #ccc;
}
.fluencyRow div.col-2{
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}
ul{
    text-align: left!important;
list-style: none!important;
}
.helpli{
color: #737272;
margin-top: 10px;
}
.singleWords{
    display: inline;
}
.textRow
{
margin-top: 5px !important;
margin-bottom: 5px !important;
border-top: 1px solid #ccc;
padding-top: 10px;
padding-bottom: 10px;
}
.badge
{
    font-size: .8rem;
    font-size: 1rem;
}
.transcriptHeading
{
font-size: .8rem;
font-size: 1rem;
font-weight: 500;
padding-top: 12px;
display: inline-block;
}
</style>
<style>
.modal-title
{
text-align: center;
width: 100%;
letter-spacing: 3px;
}
</style>