<template>
    <div class="row">
         <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Summarize Spoken Text</h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>Summarize Spoken Text</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You will hear a short lecture. Write a summary for the lecture. You should write 50-70 words. You have 10 minutes to finish this task. Your response will be judged on the Quality of Your writing and on how well your response presents the key points presented in the lecture.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Listening and writing</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 1 - 2 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>The lecture will start automatically. Sometimes the lecture is accompanied by an image or video. You need to type the summary for the same in 50-70 words.</li>
                <li>Make sure you summarize the main point and include the supporting points.</li>
                <li>Remember to check grammar, spelling and punctuation.</li>
                <li>You will be judged for listening, writing, grammar, spelling and vocabulary.</li>
                <li>Noting down the keywords or phrases will help you recalling the lecture.</li>
                <li>You also have a choice to cut, copy, or paste the text.</li>
            </ul>
            <p><strong>Cut:</strong> Select text from your answer that you wish to remove and left-click “Cut”.</p>
            <p><strong>Copy:</strong> Select text from your answer that you wish to copy and left-click “Copy”.</p>
            <p><strong>Paste:</strong> Place the cursor where you wish to paste the cut/copied text and left-click “Paste”.</p>
            </template>
            <app-ListeningExtraInfo/>
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(selectedquestion.question_id != 0)}" v-if="selectedquestion">
            <b-overlay class="fixedHeight" :show="show" rounded="sm" > 
            <h4>Summarize Spoken Text</h4>
            <p class="questHeading">You will hear a audio recording. You should write 50-70 words. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.</p>
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>{{title}} </p><app-Timer ref="timer"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.question_id" :idName="'question_id'" :tableName="'tblsummarizespokenquestion'"/>
            </div>
            </div>
            <!-- <p ><strong>{{selectedquestion.title}}</strong></p> -->
            <div v-if="questionrecording" class="audio-container">
                <audio id="questionRec" :src="questionrecording" controls /></div>
            <textarea rows="10" id='text' spellcheck="false" data-gramm="false" class="textAreaSingleLine mt-5" v-model="inputText" @keydown="recheckInput"></textarea>
            <p style="padding-top: 5px !important;padding-left: 5px !important;"><strong>Word Count: {{wordCount}}</strong></p>
            <!-- <div class="col-12 text-center">
                <br/>
                <p>{{actualAnswer}}</p>
                <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Find Answer & Marks</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>

            </div> -->
            </b-overlay>
            <div class="row">
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5">
                    <b-button :disabled="inputText == ''" class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <!-- <b-button variant="outline-dark" class="mr-2 pl-4 pr-4" @click="showModal()">Dict Mode</b-button> -->
                    <b-button :disabled="apiResults ==null" variant="outline-success" class="mr-2 pl-4 pr-4" v-b-modal.result-Modal>
                        <b-spinner v-if="resultStatus=='In-Process'" small type="grow"></b-spinner>
                        {{resultStatus}}
                    </b-button>
                    <toggle-button class="mr-2 mt-2" :width="90" :height="28" v-model="showTranscript" :value="false" :color="{checked: '#1890fb', unchecked: '#6fc8ff', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Transcript', unchecked: 'Transcript'}"/>
                    <toggle-button class="mr-2 mt-2" :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'en-US', unchecked: 'en-GB'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="showTranscript">
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">{{selectedquestion.transcript}}</p>
                    </div>
                </div>
            </div>
        </div>
         <b-modal  ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
            <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'question_id'"/></b-container>
        </b-modal>
        <b-modal id="result-Modal" size="lg" centered hide-footer title="AI Result">
         <b-container class="resultModel" >
               <app-WritingResultPresent :apiResults="apiResults"/>
         </b-container>
         <b-button class="mt-3" variant="outline-dark" left @click="$bvModal.hide('result-Modal')">Close Me</b-button>
       </b-modal>
       <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserSummarizeSpokenAnswer'"/>
        </div> 
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import WritingResultPresent from '../reuseable/writeResultPresent'
import DiscussionTab from '../reuseable/discussionTab'
import unirest from "unirest";
import ListeningExtraInfo from '../reuseable/listeningInformation.vue'
//import LocalStorageService from '../../store/LocalStorageService'
export default {
    components:{
        'app-Timer':Timer,
        'app-IntervalList': IntervalList,
        'app-WritingResultPresent':WritingResultPresent,
        'app-QuestionStatus':QuestionStatus,
        'app-DiscussionTab':DiscussionTab,
 'app-ListeningExtraInfo':ListeningExtraInfo
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            show:false,
            questId:0,
            selectedquestion: null,
            inputText:'',
            wordCount:0,
            limitCharter: 70,
            limitDone:false,
            charCount:0,
            user_type:'Free',
            questionrecording: null,
            title:'Prepare',
            userAnswerResponse:null,
            checkAnswerFlag:false,
            showTranscript:false,
            userAnswer:{
                url:'',
                user_id:0,
                question_id:0,
                answer_desc:'',
                api_result:null,
                mock_test_id:0,
                user_result:''
            },
            score:{
                content: 0,
                form: 2,
                spelling:2,
                grammer:2,
                vocab: 0,
                structure:0,
            },
            actualAnswer:'',
            apiResults:null,
            resultStatus:'AI-Result',
            isShowModal: false,
            modelTitle:'heading',
            aud:null,
            searchKey:'',
            searchQuestionList:null,
            AIWritingData:{
                analysisType:"",
                langType:"",
                questType:"",
                userAnswer:"",
                question:"",
                url:"AIWritting/AIMarkingResult",
                question_Id:0
            }
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'listeningmodule/fetchQuestion',
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'listeningmodule/questions',
            answerResponse: 'listeningmodule/AnswerResponse'
        }),
        redoQuest(){
            this.getQuestionDetail(this.selectedquestion.question_id)
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].question_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            this.questId=this.selectedquestion
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.question_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            this.inputText =''
            this.resetAll()
            ////console.log(questionId)
            this.actualAnswer = ''
            this.userAnswer.question_id = questionId;
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            //this.questionservice('configurespokenquestion/blobId='+questionId).then(()=>{
                var binary= this.selectedquestion.blb //this.getQuestionList().data.blb;
                var data = binary;//'data:audio/mpeg;base64,'+
                this.questionrecording = data;
                this.$nextTick(function(){
            this.$refs.discussionTab.updateQuestionId(questionId)

                this.$refs.timer.setTime(3)
                });
            //});
            
        },
        showAnswer(){
            this.actualAnswer = this.selectedquestion.transcript
        },
        recheckInput:function(evt){
            if(evt.keyCode == 32){
            if(this.limitCharter == this.wordCount)
            {
                this.limitDone = true;
                    this.charCount = this.inputText.length	
            }
            }
                if(this.limitDone & evt.keyCode !=32){
                    this.limitDone = false;
            }
        },
        _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        },
        timerEnd(){
           
               var aud = document.getElementById("questionRec");
               aud.play()
               this.$nextTick(function(){
                   this.title = 'Remain'
                this.$refs.timer.setTime(600)
                })
            // aud.onended = function() {
            //     $('.ar-icon.ar-icon__lg').click()
            //     }
        },
        async submitanswer() {
            try{
                if(this.inputText.length<10){
                    return this.$alert('No answer found, Against this question.')
                }
                this.resultStatus='In-Process'
                this.show=true
                var userdata =this.$ls.get('user')
                this.userAnswer.user_id = userdata.user_id
                let lang="en_UK";
                lang=(this.checkAnswerFlag)? "en-US":"en-GB";
                lang=(lang=="en-GB")?"en_UK":"en_US";
                this.AIWritingData.langType=lang;
                this.AIWritingData.analysisType="Academic"
                this.AIWritingData.questType="sst"
                this.AIWritingData.userAnswer=this.inputText
                this.AIWritingData.question=this.selectedquestion.transcript
                this.AIWritingData.question_Id=this.currentQuestion
                var vm = this
                
                this.saveAnswer(this.AIWritingData).then(()=>{
                    let AIResponse= this.answerResponse();
                    vm.apiResults=AIResponse.apiResults;
                    vm.userAnswer.api_result=JSON.stringify(vm.apiResults)
                    vm.userAnswer.mock_test_id=0
                    vm.userAnswer.answer_desc = vm.inputText;
                    vm.userAnswer.url = 'UserSpokenAnswer/createspokenanswer';
                    vm.resultStatus='AI-Result Ready'
                    vm.show=false
                    let overAllScore=(vm.apiResults.content+vm.apiResults.form+vm.apiResults.spelling
                                +vm.apiResults.grammer+vm.apiResults.vocab)
                    vm.userAnswer.user_result= 'Your Score: '+overAllScore+'/10'
                    vm.saveAnswer(vm.userAnswer).then(()=>{
                            vm.userAnswerResponse = vm.answerResponse().data
                        });

                });
                
            }
            catch(error)
            {
                this.show=false
                //console.log(error)
            }

        },
        async submitanswerOld() {
            this.resultStatus='In-Process'
            this.show=true
            try{
            const lang=(this.checkAnswerFlag)? "en-US":"en-GB";
            const  req = unirest("POST", "https://grammarbot.p.rapidapi.com/check");
            req.headers({
                "content-type": "application/x-www-form-urlencoded",
                "x-rapidapi-key": process.env.VUE_APP_GRAMMER_API_KEY,
                "x-rapidapi-host": "grammarbot.p.rapidapi.com",
                "useQueryString": true
            });

            req.form({
                "text": this.inputText,
                "language": lang//"en-US"
            });
            var vm = this
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.userAnswer.mock_test_id=0
            req.end(function (res) {
                if (res.error) throw new Error(res.error);
                //console.log(res.body)
                vm.suggestions = res.body.matches;
                vm.userAnswer.answer_desc = vm.inputText;
                vm.suggestions=(vm.suggestions.length<= 0)? 'No spelling and grammmar error':vm.suggestions
                vm.userAnswer.api_result = JSON.stringify(vm.suggestions)
                vm.checkScore()
                vm.userAnswer.url = 'UserSpokenAnswer/createspokenanswer';
                 vm.resultStatus='AI-Result Ready'
                 vm.show=false
                  
                ////console.log(this.userAnswer);
                vm.saveAnswer(vm.userAnswer).then(()=>{
                        vm.userAnswerResponse = vm.answerResponse().data
                    });
            })
}
            catch(error)
            {
                this.show=false
                //console.log(error)
            }
           
                
                
        },
        findDuplicateWords(str){
            const strArr = str.split(" ");
            const res = [];
            const commonWords=['is', 'am', 'are', 'was', 'were', 'they', 'the', 'their', 'there', 'which','it','its', 'a', 'has', 'have', 'had','when','how','who']
            for(let i = 0; i < strArr.length; i++){
                const element=strArr[i].replace(',','').replace('.','')
                if(strArr.indexOf(element) !== strArr.lastIndexOf(element)){
                    if(!res.includes(element)){
                        if(!commonWords.includes(element))
                        {res.push(element)}
                    }
                }
            }
            return res.join(" ")
        },
        checkScore(){
            ////debugger
            this.score={
                    content: 0,
                    form: 2,
                    spelling:2,
                    grammer:2,
                    vocab: 0,
                    structure:0,
                }
            if(this.inputText.length<=0){
                return this.score
            }
            var questionText=this.selectedquestion.transcript.toLowerCase().split(' ')

            var checkFullStopInLast = this.inputText.trim().slice(-1);
            var fullContent=this.inputText

            // var pronounList=['i','we','you','he','she','it','they','me','us','her','him','it','them','mine'
            // ,'ours','yours','her','his','theirs','my','our','your','myself','yourself','herself','itself',
            // 'ourself','yourself','themself'];

            var conjunctionWords=['therefore','however','moreover','thus','meanwhile','thereafter','indeed',
            'instead','consequently','eventually','finally','still','besides','because',
            'since','so that','in order','though','although','even though','while','but',
            'yet','or','so','nor','after','as long as','as soon as','before','by the time',
            'now that','once','since','till','until','when','whenever','while'];

            // var conculsionWords=['finally', 'in a word', 'in brief', 'briefly', 'in conclusion','conclusion', 'in the end', 'in the final analysis', 'on the whole'
            // , 'thus', 'to conclude', 'to summarize', 'in sum', 'to sum up', 'in summary']

            // var contrastWords=['but', 'however', 'in spite of', 'on the one hand', 'on the other hand', 'nevertheless',
            //  'nonetheless', 'notwithstanding', 'in contrast', 'on the contrary', 'still', 'yet']

            //  var exampleWords=['for example', 'for instance', 'such as','like','example','also', 'in the same way', 'just as', 'so too', 'likewise', 'similarly'
            //   ,'namely', 'specifically', 'to illustrate']
            // Content Scoring

            var inputArray=fullContent.toLowerCase().split(' ');
            var matchWordsCount=0
            var conjCount=0
            for(var i=0;i<questionText.length;i++){
                questionText[i]=questionText[i].trim();
            }
            for (var i=0; i< inputArray.length;i++){
                const element=inputArray[i].replace(',','').replace('.','')
                if(questionText.indexOf(element)>-1) matchWordsCount++
                if(conjunctionWords.indexOf(element)>-1) conjCount++
            }

            if(matchWordsCount >= 9) this.score.content = 2
            else if(matchWordsCount >= 7) this.score.content = 1.5
            else if(matchWordsCount >= 5) this.score.content = 1
            else if(matchWordsCount >= 3) this.score.content = 0.5
            else this.score.content = 0.25
           

            if(conjCount >= 2) this.score.structure = 1
            else if(conjCount >= 1) this.score.structure = .5
            else this.score.structure = 0
            if(checkFullStopInLast == '.') this.score.structure += 1

            var contentWordCount=inputArray.length
            if(contentWordCount > 50) this.score.form=2;
            else if(contentWordCount > 30 && contentWordCount <= 50) 
            {this.score.form=1;}
            else this.score.form=0;

            var transcript= this.inputText
            var grammerMistakesCount=0
            var spellingMistakeCount=0 
            if(this.suggestions!="No spelling and grammmar error")
            {
                for (let index = this.suggestions.length-1; index >=0; index--) {
                    const element = this.suggestions[index]
                    ////debugger
                    if(this.suggestions[index].rule.issueType !='misspelling') grammerMistakesCount++
                    else spellingMistakeCount++
                    const offset= element.offset
                    const length= element.length
                    const word= transcript.substring(offset,offset+length)
                    transcript = transcript.substring(0, offset) + '<span id="'+index+'" class="text-danger" style="background:yellow;">'+word+'</span>' + transcript.substring(offset+length);
                }
            }
            this.score.grammer=this.score.grammer-(grammerMistakesCount * 0.25);
            if(this.score.grammer<0)this.score.grammer=0

            this.score.spelling=this.score.spelling-(spellingMistakeCount * 0.25);
            if(this.score.spelling<0)this.score.spelling=0
            //Vacob marking
            //debugger
            const duplicateWords=this.findDuplicateWords(fullContent)
            var countDuplicate=0
            if(duplicateWords!=null) if(duplicateWords.length>0) countDuplicate=duplicateWords.split(' ').length+1;

            if(countDuplicate < 20)this.score.vocab = 2
            else if(countDuplicate < 25) this.score.vocab = 1.5
            else if(countDuplicate < 28) this.score.vocab = 1
            else if(countDuplicate < 30) this.score.vocab = .5
            else this.score.vocab = 0

            this.apiResults={
                    content: this.score.content,
                    form: this.score.form,
                    spelling:this.score.spelling,
                    grammer:this.score.grammer,
                    vocab: this.score.vocab,
                    structure: this.score.structure,
                    transcript:transcript,
                    text:this.inputText,
                    quest:'sst',
                    maxMarks:10,
                    contentMax:2

                }
            //console.log(this.apiResults)
            var  overAllScore=(this.apiResults.content+this.apiResults.form+this.apiResults.spelling
                +this.apiResults.grammer+this.apiResults.vocab)
            this.userAnswer.user_result= 'Your Score: '+overAllScore+'/10'
            
            //console.log(transcript)
            //console.log(this.score)

        },
        resetAll(){
            this.inputText = '';
            this.show=false
            this.userAnswer={
                user_id:this.$ls.get('user').user_id,
                url:'',
                question_id:0,
                answer_desc:'',
                api_result:null,
                mock_test_id:0,
                user_result:''
            },
            this.score={
                content: 0,
                form: 0,
                spellingAndGrammer:0,
                vocab: 0
            }
            this.apiResults=null
            this.resultStatus='AI-Result'
            this.isShowModal= false
            this.modelTitle='heading'
            this.aud=null
            this.searchKey=''
            this.searchQuestionList=null
            this.AIWritingData={
                analysisType:"",
                langType:"",
                questType:"",
                userAnswer:"",
                question:"",
                url:"AIWritting/AIMarkingResult"
            }
        }
    },
    beforeMount(){
        this.questionLists=(this.$ls.get('tblSummarizeSpokenQuestion')!=null)?JSON.parse(this.$ls.get('tblSummarizeSpokenQuestion')):null;
            console.log(this.questionLists)
            if(this.questionLists!=null){
                var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            }
            else{
        this.questionservice('configurespokenquestion/getallspokenquestions').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            ////console.log(this.questionLists)
        });
    }
    },
    watch: {
        inputText: function (val) {
        if(this.limitDone & this.charCount <= val.length) {
        this.inputText = this.inputText.substr(0, this.charCount)
        }
        if(this.charCount > val.length){
        this.limitDone = false;
        }
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    }
}
</script>
<style>
#answerTrans span{
    background: yellow!important;
    color:red!important;
}
#answerTrans span.possibleMistake{
    background: #f33176!important;
    color: #fff!important;

}
.noteDiv{
    text-align: right;
    width: 100%;
    font-weight: 700;
    position: absolute;
    top: -30px;
    right: 10px;
}
</style>